<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area bg-color">
    <div class="container">
        <div class="page-title-content">
            <h2>Products Features</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Features</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">FEATURES</span>
                    <h2>Most Probably You Are Getting Best App Ever</h2>
                    <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                    <div class="features-text">
                        <h6>Trusted and Reliable</h6>
                        <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                    </div>
                    <div class="features-text">
                        <h6>Cloud Storage</h6>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Start Free Trial</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/app/app-img7.png" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="features-area ptb-100 bg-F7F7FF">
    <app-features-style-one></app-features-style-one>
</div>

<div class="features-area pt-100 pb-75">
    <app-features-style-five></app-features-style-five>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-one></app-footer-style-one>
import { Component } from '@angular/core';
import { CookieService } from '../../../services/cookie.service';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss']
})
export class CookieNoticeComponent {
  showNotice: boolean = true;

  constructor(private cookieService: CookieService) {
    this.showNotice = !this.cookieService.checkCookie('gdpr_consent');
  }

  acceptCookies() {
    this.cookieService.setCookie('gdpr_consent', 'accepted', 365);
    this.showNotice = false;
  }

  declineCookies() {
    this.cookieService.setCookie('gdpr_consent', 'declined', 365);
    this.showNotice = false;
  }
}

<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy-content">
            <h1>Privacy Policy and Cookies</h1>
  
    <section>
      <h2>Privacy</h2>
      <p>At Adept AI Solutions Ltd, we are committed to ensuring that your privacy is protected. The information we collect about you when you register on our website is used to process your orders, arrange demos, and provide a more personalized experience on our site. Our site uses a 256-bit encrypted SSL to increase the protection of your personal information.</p>
    </section>
  
    <section>
      <h2>How We Use the Information We Collect</h2>
      <p>We will only collect information that is relevant to providing our online services and progressing your order. Under no circumstances will we sell or rent customer or web user information to any third party.</p>
      <p>Adept AI Solutions Ltd may share your personal information with third parties that perform services for us or on our behalf (e.g., third-party companies that provide support services for our customers). Service providers are required to keep confidential and secure the personal information received from us and may not use it for any other purpose other than as originally intended.</p>
    </section>
  
    <section>
      <h2>Our Use of Log Files</h2>
      <p>When you visit our website, we automatically log your IP address, browser type, and access times. We use this information to conduct site performance evaluations and to keep track of clickstream data. Log files are not associated with personally identifiable information.</p>
    </section>
  
    <section>
      <h2>Security</h2>
      <p>We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>
    </section>
  
    <section>
      <h2>Cookies and Our Use of Cookies</h2>
      <p>A cookie is a small text file that contains data, placed on your computer or device by our website when you visit it. The data contained in these tiny files are read each time your web browser requests a page from our web server to provide a seamless experience on the site. For more information about cookies and how to control or delete them, visit <a href="https://aboutcookies.org">aboutcookies.org</a>.</p>
      <p>We use a number of different cookies, and for you to make full use of our website and personalized content, your computer or device will need to accept cookies. The cookies we use let our website remember your login details, preferences, and what you've added to the shopping basket. Our website will NOT store your payment card details.</p>
  
      <h3>'Strictly Necessary' Cookies</h3>
      <p>These cookies are essential for you to move around the website and use its features, such as accessing secure areas. Without these cookies, services you have asked for, such as shopping baskets, cannot be provided. If these are disabled, some website functionality will be lost.</p>
  
      <h3>'Performance' Cookies</h3>
      <p>These cookies collect information about how visitors use each page, which ones are most commonly viewed, or whether any errors occurred. They don’t collect information that identifies a visitor and are only used to improve how the website works.</p>
  
      <h3>Google Analytics</h3>
      <p>Our website uses a web traffic analytical tool to understand web traffic. Google Analytics cookies collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. These cookies collect information in an anonymous form, including the number of visitors, their geographic region, and the pages they visited. If you wish to opt out of Google Analytics, please <a href="#">click here</a>.</p>
    </section>
  
    <section>
      <h2>GDPR Compliance</h2>
      <p>Adept AI Solutions will always store your personal details securely. We'll use your details to provide the service you have requested and communicate with you in the ways you have actively agreed to. Your data may also be used for analysis purposes to help us provide the best service possible and to contact you about an inquiry or order you have made. We will never pass your details on to anyone. You are free to change your mind at any time by emailing <a href="mailto:info@adeptai.co.uk">info&#64;adeptai.co.uk</a>.</p>
    </section>
  
    <section>
      <h2>Contact Details</h2>
      <p>
        Adept AI Solutions<br>
        14 Grays Road, Slough, SL1 3QG<br>
        Tel: 07459803848<br>
        Email: <a href="mailto:info@adeptai.co.uk">info&#64;adeptai.co.uk</a>
      </p>
    </section>
  
    <section>
      <h2>What Data We Collect From You</h2>
      <p>We collect information you provide, like your name, address, telephone number (landline or mobile), and email address when requesting a demo and to manage your account.</p>
    </section>
  
    <section>
      <h2>How We Use Your Data</h2>
      <p>
        - We will use your telephone number and/or email address to contact you regarding changes to your inquiry or order.<br>
        - We will use your email address to send you product updates and news, if you have opted in.<br>
        - We will use your banking details to process payments you have made to us. We do not store credit/debit card details on our system.
      </p>
    </section>
  
    <section>
      <h2>Legal Basis Under the GDPR</h2>
      <p>
        - We will process personal data where this is necessary for the fulfillment of an order with you, if you are a supplier, customer, or member of staff.<br>
        - Sometimes it may be necessary to process your data for us to comply with our legal obligations.
      </p>
    </section>
  
    <section>
      <h2>Consent</h2>
      <p>We will only process your email address to send you direct marketing if we have your consent.</p>
    </section>
  
    <section>
      <h2>Correcting/Updating Your Personal Information</h2>
      <p>After registering on the Adept AI Solutions Ltd Website, you may correct/update your personal information by clicking on the ‘My Account’ link at the top of any page. The changes you make will be reflected in our databases instantly.</p>
    </section>
  
    <section>
      <h2>Links to Other Websites</h2>
      <p>Our website contains links to other websites. Please note that when you click on these links, you may be leaving the Adept AI Solutions Ltd website and entering another website. These sites may independently collect or request information about you and are not governed by this privacy policy. We advise that you read the privacy statements applicable to the website in question.</p>
    </section>
  
    <section>
      <h2>Communication</h2>
      <p>If you have agreed to be added to our mailing list, we may send out email messages regarding available products or promotional offers. In addition, you will receive email communications from us for administration purposes, such as progressing your orders or if we need to contact you. Administration emails will not contain promotional material. If you choose to subscribe to our Newsletters, they will contain promotional material from Adept AI Solutions Ltd.</p>
    </section>
  
    <section>
      <h2>Legal Disclaimer</h2>
      <p>Though we make every effort to preserve user privacy, we may need to disclose personal information when required by law, wherein we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, court order, or legal process served on our Website.</p>
    </section>
        </div>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-footer-style-one></app-footer-style-one>
<div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card">
                    <div class="icon">
                        <i class="ri-code-line"></i>
                        <div class="number">1</div>
                    </div>
                    <h3>CodeHelper.ai</h3>
                    <p>Our intelligent coding assistant that offers real-time code suggestions, error detection, and best practices to enhance your development process.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card">
                    <div class="icon">
                        <i class="ri-advertisement-line"></i>
                        <div class="number">2</div>
                    </div>
                    <h3>AdDirect.ai</h3>
                    <p>AI-driven advertising optimization tool that maximizes reach and ROI by tailoring and automating your ad campaigns based on dynamic insights.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card">
                    <div class="icon">
                        <i class="ri-file-excel-line"></i>
                        <div class="number">3</div>
                    </div>
                    <h3>SmartXL.ai</h3>
                    <p>Advanced AI-powered Excel add-ins that provide enhanced data management, predictive analytics, and automated insights, making Excel more powerful and efficient.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card">
                    <div class="icon">
                        <i class="ri-brain-line"></i>
                        <div class="number">4</div>
                    </div>
                    <h3>Custom AI Solutions</h3>
                    <p>Tailor-made AI solutions designed to meet your unique business needs, ensuring a competitive edge through cutting-edge technology and innovation.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="new-app-progress-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-progress-content">
                    <div class="big-text">Progress</div>
                    <span class="sub-title">EMPOWER YOUR JOURNEY</span>
                    <h2>Enhance Your Performance with AI Analytics</h2>
                    <p>Leverage our cloud-based solutions to track your progress efficiently. Use AI-driven insights to set challenges, monitor performance, and achieve your goals.</p>
                    <ul class="progress-list">
                        <li>
                            <i class="ri-bar-chart-line"></i>
                            <h3>Real-Time Analytics</h3>
                            <p>Get real-time updates and analytics on your progress to make informed decisions swiftly.</p>
                        </li>
                        <li>
                            <i class="ri-pie-chart-line"></i>
                            <h3>Performance Metrics</h3>
                            <p>Track key performance metrics and visualize your progress with comprehensive reports.</p>
                        </li>
                        <li>
                            <i class="ri-dashboard-line"></i>
                            <h3>Custom Dashboards</h3>
                            <p>Create custom dashboards tailored to your specific needs, giving you a clear view of your progress.</p>
                        </li>
                        <li>
                            <i class="ri-lightbulb-line"></i>
                            <h3>AI Recommendations</h3>
                            <p>Receive AI-driven recommendations to enhance your strategies and achieve better outcomes.</p>
                        </li>
                    </ul>
                    <a routerLink="/app-download" class="default-btn">Start Free Trial</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-app-progress-image text-center">
                    <img src="assets/img/adept/AIAnalytics2.jpg" alt="app-img">
                </div>
            </div>
        </div>
    </div>
    <div class="new-app-progress-shape">
        <img src="assets/img/more-home/progress/shape-1.png" alt="image">
    </div>
</div>

<app-navbar-style-two></app-navbar-style-two>

<app-homefive-banner></app-homefive-banner>

<div class="partner-area ptb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-homefive-about></app-homefive-about>

<app-homefive-features></app-homefive-features>

<div class="features-area pb-75">
    <app-features-style-four></app-features-style-four>
</div>

<div class="screenshots-area pb-100">
    <app-screenshots-style-five></app-screenshots-style-five>
</div>

<app-pricing-style-five></app-pricing-style-five>

<div class="app-download-area ptb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div>

<app-feedback-style-four></app-feedback-style-four>

<div class="free-trial-area pt-100">
    <app-free-trial-style-three></app-free-trial-style-three>
</div>

<app-blog></app-blog>

<app-footer-style-one></app-footer-style-one>
<div class="new-app-main-banner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-content">
                    <div class="content">
                        <h1>Unleash Your Potential with Cutting-Edge AI Tools and Services</h1>
                        <p style="font-size: 17px;">Enhance your software development with our innovative code helper tools and tailored machine learning models. We boost productivity and provide customized AI solutions to meet your business needs.</p>
                        
                        <div class="content-shape">
                            <img src="assets/img/more-home/banner/content-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-image">
                    <img src="assets/img/more-home/banner/banner.png" alt="image">
                    <div class="wrap-shape-1">
                        <img src="assets/img/more-home/banner/shape-1.png" alt="image">
                    </div>
                    <div class="wrap-shape-2">
                        <img src="assets/img/more-home/banner/shape-2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="new-app-banner-bg-shape">
        <img src="assets/img/more-home/banner/banner-shape.png" alt="image">
    </div>
    <div class="new-app-banner-strock-shape">
        <img src="assets/img/more-home/banner/strock.png" alt="image">
    </div>
</div>
<div class="key-features-area pt-100 pb-75">
    <div class="container">
        <div class="section-title title-with-bg-text">
            <div class="big-title">Features</div>
            <span class="sub-title">KEY FEATURES</span>
            <h2>Empowering Your Business with AI</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card">
                    <div class="icon">
                        <i class="ri-robot-line"></i>
                    </div>
                    <h3>Intelligent Automation</h3>
                    <p>Streamline your operations with AI-driven automation, reducing manual tasks and boosting efficiency.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card bg-color-two">
                    <div class="icon bg2">
                        <i class="ri-bar-chart-box-line"></i>
                    </div>
                    <h3>Data-Driven Insights</h3>
                    <p>Leverage AI to gain valuable insights from data, enabling informed decision-making and strategic planning.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card">
                    <div class="icon">
                        <i class="ri-search-eye-line"></i>
                    </div>
                    <h3>Predictive Analytics</h3>
                    <p>Utilize predictive analytics to foresee trends, improve forecasting, and stay ahead of the competition.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card bg-color-two">
                    <div class="icon bg2">
                        <i class="ri-secure-payment-line"></i>
                    </div>
                    <h3>Enhanced Security</h3>
                    <p>Ensure data security with advanced AI algorithms that detect and prevent potential threats.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card">
                    <div class="icon">
                        <i class="ri-cloud-line"></i>
                    </div>
                    <h3>Scalable Solutions</h3>
                    <p>Implement scalable AI solutions that grow with your business, adapting to changing needs and demands.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                <div class="key-features-card bg-color-two">
                    <div class="icon bg2">
                        <i class="ri-customer-service-2-line"></i>
                    </div>
                    <h3>Improved Customer Service</h3>
                    <p>Enhance customer interactions with AI-powered chatbots and support systems, providing quick and accurate responses.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="key-features-shape-1">
        <img src="assets/img/more-home/features/shape-1.png" alt="image">
    </div>
    <div class="key-features-shape-2">
        <img src="assets/img/more-home/features/shape-2.png" alt="image">
    </div>
</div>

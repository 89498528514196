<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area bg-color">
    <div class="container">
        <div class="page-title-content">
            <h2>Screenshots</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Screenshots</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="screenshots-area bg-color ptb-100">
    <app-screenshots-style-one></app-screenshots-style-one>
</div>

<div class="screenshots-area ptb-100">
    <app-screenshots-style-two></app-screenshots-style-two>
</div>

<app-screenshots-style-three></app-screenshots-style-three>

<div class="screenshots-area ptb-100">
    <app-screenshots-style-five></app-screenshots-style-five>
</div>

<app-screenshots-style-four></app-screenshots-style-four>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-screenshots-style-six></app-screenshots-style-six>

<div class="app-download-area ptb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-three></app-footer-style-three>
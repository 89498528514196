<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">ABOUT ADEPT AI</span>
                    <h2>Welcome to ADEPT AI Solutions</h2>
                    <p style = "text-align: justify;">
                        We proudly represent the endless possibilities of tomorrow. Welcome to ADEPT AI Solutions, where innovation transcends traditional technology and ushers in a new era of artificial intelligence. Our commitment is not just to promise but to deliver groundbreaking ideas that redefine what’s possible.
                        At ADEPT AI, we strive to be at the forefront of AI innovation, providing products and services that empower both businesses and individuals. Our mission is driven by a relentless pursuit of excellence, constantly evolving to keep pace with the dynamic nature of our world.
                        We envision a future where AI seamlessly integrates with everyday life, transforming how businesses operate and enhancing human experiences. Join us at ADEPT AI, where intelligence meets innovation, and the future unfolds today.
                        </p>
                    <div class="features-text">
                        <h6>Our Services</h6>
                        <p style = "text-align: justify;">Our services encompass advanced research and development, bespoke AI strategies, and innovative product creation, all tailored to drive efficiency, growth, and competitive advantage. 
                           From machine learning and NLP to predictive analytics and intelligent automation, our expertise spans healthcare, finance, retail, manufacturing, and beyond.
                           Our dedicated consulting and training programs ensure you harness AI’s 
                           full potential, transforming challenges into opportunities with unparalleled precision and innovation.</p>
                    </div>
                    <div class="features-text">
                        <h6>Our Mission</h6>
                        <p style = "text-align: justify;">
                            At ADEPT AI, our mission is to leverage the power of artificial intelligence to drive innovation and enhance businesses. We are dedicated to developing cutting-edge AI solutions that empower businesses and individuals alike, fostering progress and efficiency.
                            Through excellence, integrity, and collaboration, we aim to lead in AI innovation, making advanced technology accessible and beneficial for all. Join us in shaping a smarter, more connected future.</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Get Started</a>
                        <a routerLink="/features-1" class="link-btn">See All Products</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                
                </div>
            </div>
        </div>
    </div>
</div>

<div class="funfacts-area pb-75">
    
</div>

<app-progress-style-two></app-progress-style-two>

<app-user-stats></app-user-stats>

<app-hometwo-software-integrations></app-hometwo-software-integrations>

<app-team-style-one></app-team-style-one>

<div class="feedback-area pb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="partner-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
<div class="app-about-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-about-image">
                    <img src="assets/img/adept/AI.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-about-content">
                    <div class="big-text">About Us</div>
                    <span class="sub-title">ABOUT US</span>
                    <h2>Driving Business Innovation with AI Solutions</h2>
                    <p>At Adept AI Solutions, we specialize in developing advanced AI tools to enhance your business operations, improve decision-making, and drive growth. Discover how our innovative products can transform your workflow and deliver exceptional results.</p>
                    <ul class="list">
                        <li>
                            <div class="icon">
                                <i class="ri-code-line"></i>
                            </div>
                            <h3>CodeHelper.ai</h3>
                            <p>Boost your development process with real-time code suggestions and error detection.</p>
                        </li>
                        <li>
                            <div class="icon bg2">
                                <i class="ri-advertisement-line"></i>
                            </div>
                            <h3>AdDirect.ai</h3>
                            <p>Optimize your advertising campaigns to achieve maximum reach and ROI.</p>
                        </li>
                        <li>
                            <div class="icon bg3">
                                <i class="ri-file-excel-line"></i>
                            </div>
                            <h3>SmartXL.ai</h3>
                            <p>Enhance Excel functionalities with AI-powered data management and analytics.</p>
                        </li>
                        <li>
                            <div class="icon bg4">
                                <i class="ri-brain-line"></i>
                            </div>
                            <h3>Custom AI Solutions</h3>
                            <p>Get tailor-made AI solutions designed to meet your unique business needs.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a href="#services" class="default-btn">Discover Our Services</a>
                        <a href="#contact" class="link-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

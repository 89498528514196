<app-navbar-style-two></app-navbar-style-two>

<app-homeone-banner></app-homeone-banner>

<app-homeone-features></app-homeone-features>

<app-homeone-video></app-homeone-video>

<app-homeone-key-features></app-homeone-key-features>

<div class="features-area ptb-100 bg-F7F7FF">
    <app-features-style-one></app-features-style-one>
</div>

<app-progress-style-one></app-progress-style-one>

<div class="app-download-area">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<div class="screenshots-area bg-color ptb-100">
    <app-screenshots-style-one></app-screenshots-style-one>
</div>

<app-software-integrations></app-software-integrations>

<div class="pricing-area bg-gradient-color pt-100 pb-75">
    <app-pricing-style-one></app-pricing-style-one>
    <div class="shape7"><img src="assets/img/shape/shape6.png" alt="shape"></div>
    <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
</div>

<div class="feedback-area ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</div>

<div class="free-trial-area pb-100">
    <app-free-trial-style-one></app-free-trial-style-one>
</div>

<app-footer-style-one></app-footer-style-one>
<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Right Sidebar</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/blog-details" class="d-block">
                                    <img src="assets/img/blog/blog1.jpg" alt="blog">
                                </a>
                                <a routerLink="/blog-grid" class="tag">Branding</a>
                            </div>
                            <div class="content">
                                <ul class="meta">
                                    <li><i class="ri-time-line"></i> April 14, 2024</li>
                                    <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(0) Comment</a></li>
                                </ul>
                                <h3><a routerLink="/blog-details">Branding involves developing strategy to create a point of differentiation</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/blog-details" class="d-block">
                                    <img src="assets/img/blog/blog3.jpg" alt="blog">
                                </a>
                                <a routerLink="/blog-grid" class="tag">Marketing</a>
                            </div>
                            <div class="content">
                                <ul class="meta">
                                    <li><i class="ri-time-line"></i> April 12, 2024</li>
                                    <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(2) Comment</a></li>
                                </ul>
                                <h3><a routerLink="/blog-details">The new minimum is a digital magazine with a header featuring a thin</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/blog-details" class="d-block">
                                    <img src="assets/img/blog/blog4.jpg" alt="blog">
                                </a>
                                <a routerLink="/blog-grid" class="tag">Branding</a>
                            </div>
                            <div class="content">
                                <ul class="meta">
                                    <li><i class="ri-time-line"></i> April 14, 2024</li>
                                    <li><i class="ri-message-2-line"></i> <a routerLink="/blog-details">(0) Comment</a></li>
                                </ul>
                                <h3><a routerLink="/blog-details">WordPress is open source software you can use to create a beautiful</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <div class="nav-links">
                                <span class="page-numbers current">1</span>
                                <a routerLink="/blog-right-sidebar" class="page-numbers">2</a>
                                <a routerLink="/blog-right-sidebar" class="page-numbers">3</a>
                                <a routerLink="/blog-right-sidebar" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <app-blog-sidebar></app-blog-sidebar>
                </aside>
            </div>
        </div>
    </div>
</div>

<app-navbar-style-two></app-navbar-style-two>
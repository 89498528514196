<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Products List</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="products-area ptb-100">
    <div class="container">
        <div class="apzel-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">6</span> products available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select class="form-select">
                        <option selected>Default</option>
                        <option value="1">Popularity</option>
                        <option value="2">Latest</option>
                        <option value="3">Price: low to high</option>
                        <option value="4">Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img1.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Laptop Blue Background</a></h3>
                        <div class="price">
                            <span class="old-price">$321</span>
                            <span class="new-price">$250</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img2.jpg" class="main-image" alt="image">
                        </a>
                        <div class="sale-tag">Sale!</div>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Pink Smartwatch</a></h3>
                        <div class="price">
                            <span class="old-price">$210</span>
                            <span class="new-price">$200</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img3.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Black Headphone</a></h3>
                        <div class="price">
                            <span class="old-price">$210</span>
                            <span class="new-price">$200</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img4.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Fashion Film Camera</a></h3>
                        <div class="price">
                            <span class="new-price">$150</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img5.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Real Life Smartwatch</a></h3>
                        <div class="price">
                            <span class="new-price">$240</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details" class="main-image">
                            <img src="assets/img/products/products-img6.jpg" class="main-image" alt="image">
                        </a>
                        <a routerLink="/cart" class="default-btn">Add To Cart</a>
                        <div class="new-tag">New!</div>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/products-details">Joystick Controller</a></h3>
                        <div class="price">
                            <span class="old-price">$150</span>
                            <span class="new-price">$100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area">
                    <div class="nav-links">
                        <span class="page-numbers current">1</span>
                        <a routerLink="/products" class="page-numbers">2</a>
                        <a routerLink="/products" class="page-numbers">3</a>
                        <a routerLink="/products" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="app-download-area pb-100">
    <app-download-app-style-one></app-download-app-style-one>
</div>

<app-footer-style-one></app-footer-style-one>
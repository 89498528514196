<app-navbar-style-two></app-navbar-style-two>

<app-homeeight-banner></app-homeeight-banner>

<app-homeeight-features></app-homeeight-features>

<app-homeeight-about></app-homeeight-about>

<app-features-style-seven></app-features-style-seven>

<app-homeeight-screenshots></app-homeeight-screenshots>

<app-homeeight-intro></app-homeeight-intro>

<app-funfacts-style-three></app-funfacts-style-three>

<app-download-app-style-five></app-download-app-style-five>

<div class="feedback-wrap-area ptb-100">
    <app-feedback-style-two></app-feedback-style-two>
</div>

<app-pricing-style-seven></app-pricing-style-seven>

<app-homeeight-blog></app-homeeight-blog>

<div class="partner-area pb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-footer-style-five></app-footer-style-five>
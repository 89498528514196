<div class="app-progress-area bg-black ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-animation-image">
                    <img src="assets/img/app-progress/progress-main.png" alt="app-progress">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content text-white">
                    <span class="sub-title">AI Progress</span>
                    <h2>Monitor Growth And Progression</h2>
                    <p>Driving innovation, customising solutions and advocating continuous improvement</p>
                    <p>Our services are designed to be progressive, continually evolving to meet the ever-changing needs of our clients and the broader technological landscape. 
                    We pride ourselves on being at the cutting edge of AI innovation, ensuring that our solutions are not only relevant but also transformative.</p>
                    <a routerLink="/pricing" class="default-btn">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>